/*
  Theme Name: Roofx - Roofing Services HTML Template
  Support: themegenix@gmail.com
  Description: Roofx - Roofing Services HTML Template.
  Version: 1.0
*/

/* CSS Index
============================
01. Variable CSS
02. Button style
03. Header
04. Mobile Menu
05. Banner
06. Breadcrumb
07. Slider
08. Brand
09. Features
10. About
11. Services
12. Support
13. Project
14. Appointment
15. Team
16. Engineer
17. Work
18. History
19. Counter
20. Testimonial
21. Faq
22. Blog
23. Pagination
24. Newsletter
25. Contact
26. Error
27. Footer
28. Preloader
============================
*/

/*=============================
	1. Google Fonts
===============================*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*=============================
	01. Variable CSS
===============================*/
:root {
    --tg-body-font-family: "DM Sans", sans-serif;
    --tg-heading-font-family: "Poppins", sans-serif;
    --tg-primary-color: #e60f21;
    --tg-secondary-color: #131944;
    --tg-body-font-color: #61657e;
    --tg-heading-font-color: #1c1c1c;
    --tg-paragraph-color: #61657e;
    --tg-menu-color: #0c1239;
    --tg-body-font-size: 16px;
    --tg-body-font-weight: 400;
    --tg-heading-font-weight: 700;
    --tg-body-line-height: 1.62;
    --tg-heading-line-height: 1.2;
    --tg-blue: #161c49;
    --tg-blue-two: #0f1437;
    --tg-blue-three: #0a0e21;
    --tg-border-color: #d8defc;
    --tg-input-border-color: #cfcfcf;
    --tg-indigo: #6610f2;
    --tg-purple: #6f42c1;
    --tg-pink: #d63384;
    --tg-red: #dc3545;
    --tg-orange: #fd7e14;
    --tg-seashell: #fff3ed;
    --tg-unbleached-silk: #ffdac9;
    --tg-yellow: #ffc107;
    --tg-green: #198754;
    --tg-teal: #20c997;
    --tg-cyan: #0dcaf0;
    --tg-white: #ffffff;
    --tg-gray: #f4f6ff;
    --tg-gray-two: #edf0ff;
    --tg-gray-three: #b8b8b8;
    --facebook: #3b5998;
    --twitter: #00acee;
    --linkedin: #1976d2;
    --pinterest: #3b5998;
    --youtube: #c4302b;
    --skype: #00aff0;
}
.response-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    text-align: center;
}

.response-message.success {
    background-color: green;
}

.response-message.error {
    background-color: red;
}

body {
    font-family: var(--tg-body-font-family);
    font-weight: var(--tg-body-font-weight);
    font-size: var(--tg-body-font-size);
    line-height: var(--tg-body-line-height);
    color: var(--tg-body-font-color);
    font-style: normal;
}
img,
.img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
.f-left {
    float: left;
}
.f-right {
    float: right;
}
.fix {
    overflow: hidden;
}
a,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
}
a:focus,
.btn:focus,
button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
    color: var(--tg-primary-color);
    text-decoration: none;
}
a,
button {
    color: var(--tg-primary-color);
    outline: medium none;
    text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    margin-top: 0px;
    margin-bottom: 0.7rem;
    font-style: normal;
    line-height: var(--tg-heading-line-height);
    font-weight: var(--tg-heading-font-weight);
    text-transform: inherit;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.75rem;
}
h4 {
    font-size: 1.5rem;
}
h5 {
    font-size: 1.25rem;
}
h6 {
    font-size: 1rem;
}
.list-wrap {
    margin: 0px;
    padding: 0px;
}
.list-wrap li {
    list-style: none;
}
p {
    font-size: var(--tg-body-font-size);
    font-weight: var(--tg-body-font-weight);
    line-height: var(--tg-body-line-height);
    color: var(--tg-paragraph-color);
    margin-bottom: 15px;
}
hr {
    border-bottom: 1px solid var(--tg-primary-color);
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}
label {
    color: var(--tg-body-font-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}
*::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}
::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}
::selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}
*::-moz-placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}
*::placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}
.theme-overlay {
    position: relative;
}
.theme-overlay::before {
    background: var(--tg-primary-color) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
}
.separator {
    border-top: 1px solid var(--tg-primary-color);
}

/* Bootstrap 5 */
.container {
    padding-left: 15px;
    padding-right: 15px;
}
.row {
    --bs-gutter-x: 30px;
}
.row.g-0 {
    --bs-gutter-x: 0;
}
.gutter-y-30 {
    --bs-gutter-y: 30px;
}

/*=============================
	02. Button style
===============================*/
.btn {
    user-select: none;
    -moz-user-select: none;
    background: var(--tg-primary-color) none repeat scroll 0 0;
    border: medium none;
    color: var(--tg-white);
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 0;
    padding: 20px 31px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 4px;
    font-family: var(--tg-heading-font-family);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.btn::after {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    margin-left: 15px;
}
.btn::before {
    content: "";
    position: absolute;
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
    width: 200%;
    height: 200%;
    top: 110%;
    left: 50%;
    background: var(--tg-secondary-color);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 50%;
    z-index: -1;
}
.btn:hover:before {
    top: -40%;
}
.btn:hover {
    color: var(--tg-white);
}
.btn.btn-two::after {
    display: none;
}
.breadcrumb > .active {
    color: var(--tg-primary-color);
}

/* scrollUp */
.scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: -10%;
    right: 50px;
    font-size: var(--tg-body-font-size);
    border-radius: 6px;
    z-index: 99;
    color: var(--tg-white);
    text-align: center;
    cursor: pointer;
    background: var(--tg-primary-color);
    transition: 1s ease;
    border: none;
}
.scroll-top.open {
    bottom: 30px;
}
.scroll-top::after {
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll-top:hover {
    background: var(--tg-secondary-color);
}

.response-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    z-index: 10000;
    background-color: rgba(0, 128, 0, 0.8); /* Fondo verde */
    color: white; /* Texto blanco */
}

.response-message.error {
    background-color: rgba(255, 0, 0, 0.8); /* Fondo rojo para errores */
}

/*=============================
	03. Header
===============================*/
/* Añadir este bloque para hacer el header flotante */
.sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: var(--tg-white); /* Asegúrate de que el fondo sea visible */
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
}
/* Ajusta el contenido principal para que no quede oculto debajo del header flotante */
.main-content {
    padding-top: 100px; /* Ajusta este valor según la altura del header */
}
.custom-container {
    max-width: 1330px;
}
.transparent-header {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 9;
    height: auto;
}
.logo img {
    max-width: 511px;
}
.sticky-menu .logo.d-none {
    display: block !important;
}
.sticky-menu .logo.different-logo {
    display: none;
}
.menu-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
.navbar-wrap ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0;
    margin: 0 auto;
}
.navbar-wrap ul li {
    list-style: none;
    display: block;
    position: relative;
}
.navbar-wrap ul li a {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--tg-menu-color);
    font-family: var(--tg-heading-font-family);
    padding: 42px 22px;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
}
.navbar-wrap > ul > li.menu-item-has-children > a::after {
    content: "\f078";
    font-weight: 700;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    margin-left: 5px;
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
    color: var(--tg-primary-color);
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
    display: none;
}
.header-action > ul {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.header-action > ul li {
    position: relative;
    margin-left: 30px;
}
.header-action ul li:first-child {
    margin-left: 0;
}
.header-action ul li a {
    color: var(--tg-heading-font-color);
    font-size: 16px;
}
.header-action .header-btn .btn {
    color: var(--tg-white);
    font-size: 20px;
}
.navbar-wrap ul li .sub-menu {
    font-size: 16px; /* Aumentado de 16px a 18px */
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    min-width: 230px;
    border: 1px solid #f5f5f5;
    background: var(--tg-white);
    margin: 0 0;
    transform: scale(1, 0);
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    -moz-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    border-radius: 0 0 8px 8px;
    padding: 18px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
}
.navbar-wrap ul li .sub-menu .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
}
.navbar-wrap ul li .sub-menu li {
    margin-left: 0;
    text-align: left;
    display: block;
}
.navbar-wrap ul li .sub-menu li a {
    padding: 9px 15px 9px 25px;
    line-height: 1.4;
    font-weight: 500;
    color: var(--tg-menu-color);
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.navbar-wrap ul li .sub-menu li a:hover,
.navbar-wrap ul li .sub-menu li.active a {
    color: var(--tg-primary-color);
}
.navbar-wrap ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}
.sticky-menu {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    z-index: 99;
    background: var(--tg-white);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
}
#header-fixed-height.active-height {
    display: block;
    height: 100px;
}

/* header-two */
.menu-area-two .navbar-wrap ul {
    margin: 0px 0px 0 auto;
}
.menu-area-two .header-action > ul {
    margin-left: 80px;
}

/* header-three */
.header-top-wrap {
    background: var(--tg-blue-three);
    padding: 12px 0;
}
.header-top-left .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 60px;
}
.header-top-left .list-wrap li {
    font-size: 14px;
    font-weight: 400;
    color: var(--tg-white);
    position: relative;
}
.header-top-left .list-wrap li::before {
    content: "";
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 26px;
    background: var(--tg-primary-color);
    opacity: 0.5;
}
.header-top-left .list-wrap li:first-child::before {
    display: none;
}
.header-top-left .list-wrap li i {
    color: var(--tg-primary-color);
    font-size: 16px;
    margin-right: 10px;
}
.header-top-left .list-wrap li a {
    color: var(--tg-white);
}
.header-top-left .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.header-top-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px 45px;
}
.header-lang .dropdown > button {
    border: none;
    background: transparent;
    padding: 0;
    color: var(--tg-white);
    font-weight: 400;
    font-size: 14px;
}
.header-lang img {
    max-width: 19px;
    margin-right: 7px;
    border-radius: 1px;
}
.header-lang .dropdown-toggle::after {
    margin-left: 0.255em;
    vertical-align: middle;
    content: "\f078";
    border: none !important;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
}
.header-lang .dropdown-menu {
    font-size: 14px;
    color: var(--tg-white);
}
.header-social .list-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}
.header-social .list-wrap li {
    position: relative;
}
.header-social .list-wrap li::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 26px;
    background: var(--tg-primary-color);
    opacity: 0.5;
}
.header-social .list-wrap li:first-child::before {
    display: none;
}
.header-social .list-wrap li a {
    font-size: 20px;
    color: var(--tg-white);
    line-height: 1;
}
.header-social .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.menu-area-three .navbar-wrap ul {
    margin: 0 0 0 80px;
}

/* header-four */
.menu-area-four .menu-wrap {
    background: var(--tg-white);
    padding: 0 30px;
    border-radius: 4px;
}
.menu-area-four .logo img {
    max-width: 120px;
}
.menu-area-four .navbar-wrap ul li a {
    padding: 32px 22px;
}
.menu-area-four .navbar-wrap ul {
    margin: 0 0 0 70px;
}
.menu-area-four.transparent-header {
    top: 30px;
}
.menu-area-four.transparent-header.sticky-menu {
    top: 0;
}

/*=============================
	04. Mobile Menu
===============================*/
.nav-outer .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    display: none;
    color: var(--tg-white);
    margin-right: 30px;
    top: 15px;
}
.nav-logo img {
    width: 150px;
}
.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 0px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
    -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
}
.mobile-menu .navbar-collapse {
    display: block !important;
}
.mobile-menu .nav-logo {
    position: relative;
    padding: 30px 25px;
    text-align: left;
}
.mobile-menu-visible {
    overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}
.mobile-menu .navigation li.current > a:before {
    height: 100%;
}
.menu-backdrop {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.5);
}
.mobile-menu-visible .menu-backdrop {
    opacity: 1;
    visibility: visible;
}
.mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--tg-white);
    padding: 0px 0px;
    z-index: 5;
    box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .menu-box {
    opacity: 1;
    visibility: visible;
}
.mobile-menu .close-btn {
    position: absolute;
    right: 15px;
    top: 28px;
    line-height: 30px;
    width: 35px;
    text-align: center;
    font-size: 20px;
    color: var(--tg-primary-color);
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
    margin: 0;
    padding: 0;
}
.mobile-menu .navigation ul {
    padding: 0;
    margin: 0;
}
.mobile-menu .navigation li {
    position: relative;
    display: block;
    border-top: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation:last-child {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation li > ul > li:first-child {
    border-top: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation li > a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 60px 10px 25px;
    font-size: 15px;
    font-weight: 600;
    color: var(--tg-menu-color);
    font-family: var(--tg-heading-font-family);
    text-transform: capitalize;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}
.mobile-menu .navigation li ul li > a {
    font-size: 15px;
    margin-left: 20px;
    text-transform: capitalize;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.mobile-menu .navigation li ul li ul li a {
    margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
    margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
    position: absolute;
    right: 15px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open i {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: var(--tg-primary-color);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
    display: none;
}
.mobile-menu .social-links ul {
    display: flex;
    position: relative;
    text-align: center;
    padding: 30px 20px 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.mobile-menu .social-links li {
    position: relative;
    display: inline-block;
    margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
    line-height: 32px;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: 1px solid var(--tg-gray);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
    border-color: var(--tg-primary-color);
    background: var(--tg-primary-color);
    color: var(--tg-white);
}
.menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 30px;
    cursor: pointer;
    line-height: 1;
    color: var(--tg-primary-color);
    display: none;
    margin-top: 7px;
}

/*=============================
	05. Banner
===============================*/

.banner-area {
    position: relative;
    padding: 100px 0 0 240px;
}
.banner-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 22.29%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.banner-bg {
    padding: 100px 115px;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 10px 0 0 10px;
    z-index: 1;
    min-height: 708px;
    display: flex;
    align-items: center;
}
.banner-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 39.52%, rgba(0, 0, 0, 0) 55.54%);
    border-radius: 10px 0px 0px 10px;
    z-index: -1;
}
.banner-content {
    width: 48%;
    flex: 0 0 auto;
}
.banner-content .title {
    font-size: 56px;
    margin-bottom: 30px;
    color: var(--tg-white);
}
.banner-content p {
    font-size: 20px;
    margin-bottom: 45px;
    color: var(--tg-white);
    font-weight: 400;
    font-family: var(--tg-heading-font-family);
}
.tooltip-btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}
.tooltip-content {
    width: 315px;
    background: var(--tg-white);
    padding: 20px 25px;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 75px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s linear;
}
.tooltip-content::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    border-style: solid;
    border-width: 18px 17px 0px 17px;
    border-color: #fff transparent transparent;
}
.tooltip-item.active .tooltip-content {
    opacity: 1;
    visibility: visible;
}
.tooltip-content .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}
.tooltip-content p {
    margin-bottom: 0;
}
.pulse::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 100%;
    background: var(--tg-primary-color);
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
    opacity: 0.5;
}
@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
@-webkit-keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
.banner-tooltip-wrap {
    position: relative;
    width: 52%;
    flex: 0 0 auto;
    min-height: 440px;
}
.tooltip-item {
    position: absolute;
}
.tooltip-item:nth-child(1) {
    top: 0;
    right: 60%;
}
.tooltip-item:nth-child(2) {
    right: 18%;
    bottom: 40%;
}
.tooltip-item:nth-child(3) {
    left: 15%;
    bottom: 14%;
}
.tooltip-item.top .tooltip-content {
    bottom: auto;
    top: 75px;
}
.tooltip-item.top .tooltip-content::before {
    bottom: auto;
    top: -18px;
    transform: translateX(-50%) rotate(180deg);
}

/* banner-two */
.banner-area-two {
    background: var(--tg-blue-three);
    padding: 110px 0 240px;
    position: relative;
    z-index: 1;
}
.banner-content-two .sub-title {
    font-weight: 500;
    font-size: 25px;
    display: block;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 10px;
}
.banner-content-two .title {
    font-size: 60px;
    margin-bottom: 20px;
    color: var(--tg-white);
}
.banner-content-two p {
    font-size: 18px;
    margin-bottom: 50px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}
.banner-img-wrap {
    position: relative;
    margin-left: 100px;
    z-index: 1;
}
.banner-img-wrap img {
    border-radius: 10px;
    position: relative;
}
.banner-img-wrap::before {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    width: 100%;
    height: 100%;
    background: #1b2242;
    border-radius: 10px;
    z-index: -1;
    transition: 0.3s linear;
}
.banner-img-wrap:hover::before {
    left: 0;
    top: 0;
}
.banner-img-wrap .overly-text {
    position: absolute;
    left: -126px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: -1;
    writing-mode: vertical-rl;
}
.banner-img-wrap .overly-text .title {
    margin-bottom: 0;
    font-weight: 900;
    font-size: 70px;
    -webkit-text-fill-color: var(--tg-blue-three);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255 255 255 / 20%);
    line-height: 0.8;
    white-space: nowrap;
}
.banner-shape-wrap .list-wrap li {
    position: absolute;
    z-index: -1;
}
.banner-shape-wrap .list-wrap li:first-child {
    width: 94px;
    height: 94px;
    left: 3%;
    top: 6%;
}
.banner-shape-wrap .list-wrap li:nth-child(2) {
    width: 121px;
    height: 148px;
    left: 4%;
    bottom: 25%;
}
.banner-shape-wrap .list-wrap li:nth-child(3) {
    width: 113px;
    height: 118px;
    top: 3%;
    right: 4%;
}
.banner-shape-wrap .list-wrap li:nth-child(4) {
    width: 89px;
    height: 89px;
    right: 42%;
    top: 6%;
    z-index: 1;
}
.banner-shape-wrap .list-wrap li:nth-child(5) {
    left: 5%;
    top: 13%;
}
.banner-shape-wrap .list-wrap li:nth-child(6) {
    right: 0;
    bottom: 128px;
}
.banner-shape-wrap .list-wrap li:nth-child(7) {
    left: 36%;
    bottom: 36%;
}
.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* banner-three */
.banner-area-three.banner-two {
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 948px;
    display: flex;
    align-items: center;
    padding: 200px 0;
    z-index: 1;
}
.banner-area-three.banner-two::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(12, 18, 57, 0.27) 0%, #0c1239 100%);
    z-index: -1;
}
.banner-content-three {
    text-align: center;
    margin-top: -20px;
}
.banner-content-three .title {
    font-size: 60px;
    margin-bottom: 35px;
    color: var(--tg-white);
    line-height: 1.33;
}
.banner-content-three p {
    font-weight: 500;
    font-size: 20px;
    color: var(--tg-white);
    opacity: 0.8;
    width: 80%;
    margin: 0 auto 50px;
}

/*=============================
	06. Breadcrumb
===============================*/
.breadcrumb-bg {
    background-size: cover;
    background-position: center;
    padding: 105px 0;
    position: relative;
    z-index: 1;
}
.breadcrumb-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 877px;
    height: 242px;
    z-index: -1;
}
.breadcrumb-content .title {
    margin-top: 30px; /* Ajusta este valor según necesites */
    margin-bottom: 20px;
    color: var(--tg-white);
    font-weight: 500;
    font-size: 46px;
    line-height: 1;
}

.breadcrumb-content .breadcrumb {
    margin-top: 10px; /* Ajusta este valor según necesites */
    margin-bottom: 0;
}

.breadcrumb-content .breadcrumb-item {
    font-size: 16px;
    font-weight: 400;
    color: var(--tg-primary-color);
    line-height: 0.5;
}
.breadcrumb-content .breadcrumb-item a {
    color: var(--tg-white);
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 10px;
    color: var(--tg-white);
    content: "-";
    font-size: 16px;
    font-weight: 700;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
    padding-left: 10px;
}

/*=============================
	07. Slider
===============================*/
.slider-area {
    position: relative;
    padding: 100px 0 0 484px;
    margin-bottom: 120px;
}
.slider-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 1013px;
    height: 525px;
    z-index: 1;
}
.slider-content-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 49.8%;
    z-index: 1;
    background: var(--tg-blue-two);
    border-radius: 0px 150px 12px 0px;
}
.slider-content {
    padding: 120px 40px 120px 240px;
}
.slider-content .overly-text {
    background: linear-gradient(197deg, #ffffff 11.71%, #0f1437 89.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    opacity: 0.14;
    font-weight: 900;
    font-size: 71px;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    left: -20px;
    line-height: 1;
}
.slider-content .sub-title {
    font-weight: 500;
    font-size: 25px;
    display: block;
    line-height: 1;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 17px;
}
.slider-content .title {
    color: var(--tg-white);
    margin-bottom: 16px;
    font-size: 60px;
}
.slider-content p {
    margin-bottom: 50px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
    font-size: 18px;
}
.slider-content .btn::before {
    background: var(--tg-white);
}
.slider-content .btn:hover {
    color: var(--tg-primary-color);
}
.slider-img-item {
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 770px;
    border-radius: 200px 0px 0px 12px;
    cursor: grab;
}
.slider-img-wrap {
    overflow: hidden;
}
.banner-nav {
    display: inline-flex;
    align-items: center;
    background: var(--tg-blue-two);
    border-radius: 4px;
    position: absolute;
    bottom: 190px;
    padding: 35px 30px;
    gap: 50px;
    right: 12%;
}
.banner-nav .slick-arrow {
    border: none;
    padding: 0;
    background: transparent;
    font-size: 60px;
    color: var(--tg-white);
    opacity: 0.3;
    line-height: 0;
}
.banner-nav .slick-arrow:hover {
    opacity: 1;
}

/*=============================
	08. Brand
===============================*/
.brand-area {
    background: var(--tg-gray);
    z-index: -1;
    padding: 46.5px 0;
}
.brand-item {
    display: flex;
    align-items: center;
    height: 47px;
    justify-content: center;
}
.brand-item img {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    max-height: 47px;
    filter: grayscale(100%);
}
.brand-item img:hover {
    filter: grayscale(0);
}
.brand-inner {
    margin-left: 85px;
}

/* brand-two */
.brand-area-two {
    margin-left: -484px;
    padding: 33px 0;
}
.brand-active-two [class*="col-"] {
    padding: 0 15px;
}
.brand-area-two .brand-item {
    display: flex;
    align-items: center;
    height: 39px;
    justify-content: center;
}
.brand-area-two .brand-title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 30px;
    color: #111111;
    opacity: 0.8;
}
.brand-area-two .brand-item img {
    max-height: 39px;
    opacity: 0.5;
}
.brand-area-two .brand-item img:hover {
    opacity: 1;
}

/* inner-brand-area */
.inner-brand-area .brand-item {
    height: 120px; /* Aumenta la altura del contenedor */
    background: var(--tg-gray);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.inner-brand-area .brand-item img {
    max-height: 100px; /* Aumenta el tamaño máximo de las imágenes */
}
.inner-brand-area .brand-active-three {
    margin: 0 -10px;
}
.inner-brand-area .brand-active-three [class*="col-"] {
    padding: 0 10px;
}
.inner-brand-area .brand-item:hover {
    background: var(--tg-white);
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.06);
}
.inner-brand-area .brand-item:hover img {
    filter: grayscale(0);
}

/*=============================
	09. Features
===============================*/
.features-item {
    padding: 0 50px;
    position: relative;
    margin-bottom: 30px;
}
.features-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 150px;
    background: var(--tg-seashell);
    border-radius: 10px;
    z-index: -1;
}
.features-item-inner {
    border-radius: 10px;
    background: var(--tg-white);
    padding: 45px 48px 50px;
    box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.features-content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 13px;
}
.features-content p {
    margin-bottom: 20px;
}
.features-content .link-btn {
    display: inline-flex;
    align-items: center;
    color: var(--tg-paragraph-color);
    font-size: 18px;
}
.features-content .link-btn i {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--tg-paragraph-color);
    font-size: 20px;
    line-height: 1;
    margin-right: 17px;
    transition: all 0.3s ease-out 0s;
}
.features-item-inner:hover .features-content .link-btn i,
.features-item-inner:hover .features-content .link-btn {
    color: var(--tg-primary-color);
}
.features-icon {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    font-size: 40px;
    background: var(--tg-unbleached-silk);
    color: var(--tg-primary-color);
    position: absolute;
    bottom: -50px;
    right: -38px;
    padding: 40px 40px;
    line-height: 0;
    transition: 0.3s linear;
}
.features-item-inner:hover .features-icon img {
    transition: 0.3s;
}
.features-icon::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background: var(--tg-seashell);
    z-index: -1;
}
.features-item-inner:hover .features-icon {
    background: var(--tg-primary-color);
}
.features-item-inner:hover .features-icon img {
    filter: brightness(0) invert(1);
}

/* features-two */
.features-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
}
.features-item-two {
    border-radius: 6px 60px 6px 6px;
    padding: 16px 24px 24px;
    margin: 10px 10px 0 0;
    position: relative;
    background: var(--tg-white);
    z-index: 1;
    margin-bottom: 30px;
}
.features-item-shape {
    position: absolute;
    right: -10px;
    top: -9px;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.features-item-shape svg {
    color: var(--tg-blue-two);
    width: 100%;
    height: 100%;
    transition: 0.3s linear;
}
.features-item-two:hover .features-item-shape svg {
    color: var(--tg-primary-color);
}
.features-item-two::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px 60px 6px 6px;
    background: var(--tg-white);
    z-index: -1;
}
.features-item-top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.features-icon-two {
    width: 78px;
    height: 78px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--tg-primary-color);
    border-radius: 4px;
    margin-right: 20px;
    font-size: 40px;
    color: var(--tg-primary-color);
}
.features-content-two .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
}
.features-content-two p {
    margin-bottom: 0;
}
.features-thumb {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}
.features-thumb img {
    transition: 0.5s;
    transform: scale(1);
    width: 100%;
}
.features-item-two:hover .features-thumb img {
    transform: scale(1.1);
}
.features-thumb .link-btn {
    width: 54px;
    height: 54px;
    position: absolute;
    right: 20px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-blue-two);
    border-radius: 50%;
    font-size: 25px;
    color: var(--tg-white);
}
.features-thumb .link-btn img {
    width: 25px;
}
.features-item-two:hover .features-thumb .link-btn {
    background: var(--tg-primary-color);
}
/* fetures-three */
.features-area-three.features-bg-two {
    background-size: cover;
    background-position: center;
    padding: 0 0 90px;
}
.features-item-wrap {
    margin-top: -138px;
    position: relative;
    z-index: 1;
}
.fetures-item-three {
    padding: 16px 16px 24px;
    background: var(--tg-white);
    border-radius: 6px;
    margin-bottom: 30px;
}
.fetures-thumb-three {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}
.features-thumb-three img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s linear;
    transform: scale(1);
}
.fetures-item-three:hover .fetures-thumb-three img {
    transform: scale(1.1);
}
.fetures-content-three {
    position: relative;
    padding: 27px 12px 0;
}
.fetures-icon-three {
    width: 76px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 40px;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    position: absolute;
    right: 15px;
    top: -38px;
}
.fetures-icon-three img {
    filter: brightness(0) invert(1);
}
.fetures-content-three .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
    width: 75%;
}
.fetures-content-three p {
    margin-bottom: 0;
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .features-thumb-three {
        width: 100%; /* Ensure the container is responsive */
        height: auto; /* Allow the height to adjust based on content */
    }

    .features-thumb-three img {
        width: 100%; /* Ensure the image takes up the full width of its container */
        height: auto; /* Allow the image height to adjust based on content */
        object-fit: cover; /* Ensure the image covers the entire container without distortion */
    }

    .features-item-three {
        margin-bottom: 20px;
    }
}

/*=============================
	10. About
===============================*/
.section-title .sub-title {
    font-size: 20px;
    display: block;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 14px;
}
.section-title .title {
    font-size: 40px;
    margin-bottom: 0;
}
.about-content p {
    margin-bottom: 18px;
}
.about-list .list-wrap {
    margin-bottom: 50px;
}
.about-list .list-wrap li {
    display: flex;
    align-items: baseline;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    font-size: 18px;
    margin-bottom: 10px;
}
.about-list .list-wrap li:last-child {
    margin-bottom: 0;
}
.about-list .list-wrap li i {
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-primary-color);
    box-shadow: 4px 4px 15px rgba(254, 93, 20, 0.4);
    color: var(--tg-white);
    font-size: 12px;
    margin-right: 15px;
}
.about-img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 38px;
}
.about-img-wrap > img {
    border-radius: 10px;
}
.about-img-wrap img:nth-child(2) {
    margin-left: 20px;
}
.experiences-item {
    box-shadow: 6px 6px 50px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 20px;
    width: 352px;
    display: flex;
    align-items: center;
    position: absolute;
    background: var(--tg-white);
    left: 70px;
    bottom: 87px;
}
.experiences-item .icon {
    width: 63px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    border-radius: 5px;
    color: var(--tg-white);
    font-size: 30px;
    flex: 0 0 auto;
    margin-right: 20px;
}
.experiences-item .content .title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 1.55;
}
.experiences-item:nth-child(2) {
    bottom: -38px;
    left: 145px;
}

/* about-two */
.about-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.about-content-two {
    width: 85%;
}
.about-content-two p {
    margin-bottom: 30px;
}
.about-list-two .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-bottom: 50px;
}
.about-list-two .list-wrap .list-item {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 15px;
}
.about-list-two .list-wrap .list-item .icon {
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: rgb(254 93 20 / 16%);
    font-size: 30px;
    color: var(--tg-primary-color);
    margin-right: 15px;
}
.about-list-two .list-wrap .list-item .content .title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
}
.about-img-wrap-two {
    display: flex;
    align-items: center;
    gap: 20px;
}
.about-img-wrap-two .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
}
.about-img-wrap-two .list-wrap:nth-child(1) li:nth-child(2) > img {
    width: 311px;
    height: 337px;
    border-radius: 5px;
    object-fit: cover;
}
.about-img-wrap-two .list-wrap:nth-child(2) li:nth-child(1) > img {
    width: 373px;
    height: 419px;
    object-fit: cover;
    border-radius: 5px 40px 5px 5px;
}
.about-img-wrap-two .list-wrap:nth-child(2) li:nth-child(2) > img {
    border-radius: 5px;
    width: 373px;
    height: 210px;
    object-fit: cover;
}
.year-experience-wrap {
    background: var(--tg-primary-color);
    border-radius: 5px 40px 5px 5px;
    padding: 43px 38px;
    position: relative;
}
.year-experience-wrap .icon {
    width: 82px;
    height: 80px;
    position: absolute;
    left: 17px;
    top: 17px;
}
.year-experience-wrap .title {
    margin-bottom: 0;
    color: var(--tg-white);
    font-size: 54px;
    line-height: 1;
}
.year-experience-wrap .title span {
    display: block;
    font-weight: 500;
    font-size: 40px;
}
.about-shape-wrap .list-wrap li {
    position: absolute;
    z-index: -1;
}
.about-shape-wrap .list-wrap li:nth-child(1) {
    width: 125px;
    height: 155px;
    left: 18%;
    top: 10%;
}
.about-shape-wrap .list-wrap li:nth-child(2) {
    width: 175px;
    height: 185px;
    left: 5%;
    bottom: 12%;
    opacity: 0.2;
}
.about-shape-wrap .list-wrap li:nth-child(3) {
    width: 242px;
    height: 294px;
    right: 1%;
    bottom: 7%;
    opacity: 0.2;
}

.img-369x550 {
    width: 100%;
    max-width: 369px;
    height: 550px;
    object-fit: cover;
}

.img-223x473 {
    width: 100%;
    max-width: 223px;
    height: 473px;
    object-fit: cover;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
    .img-369x550,
    .img-223x473 {
        max-width: 100%;
        height: auto;
        max-height: none;
    }

    .about-img-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
/*=============================
	11. Services
===============================*/
.services-area {
    background: var(--tg-gray);
    padding: 115px 0 90px;
}
.services-item {
    padding: 50px 45px 50px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 30px;
    min-height: 286px;
    object-fit: cover;
}
.services-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--tg-white);
    border-radius: 5px;
    z-index: -1;
    transition: 0.3s linear;
}
.services-item:hover::before {
    opacity: 0;
}
.services-item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
    background: linear-gradient(100.16deg, #fe5d14 1.96%, rgba(28, 28, 28, 0) 198.08%);
    opacity: 0;
    transition: 0.3s linear;
}
.services-item:hover::after {
    opacity: 0.95;
}
.services-icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    font-size: 40px;
    border-radius: 50px;
    box-shadow: 0px 0px 30px rgba(254, 93, 20, 0.4);
    margin-bottom: 55px;
}
.services-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 0;
}
.services-content .number {
    margin-bottom: 0;
    position: absolute;
    right: 8px;
    top: 10px;
    background: linear-gradient(180deg, rgba(254, 93, 20, 0.14) 0%, rgba(254, 93, 20, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 400;
    font-size: 100px;
    line-height: 0.8;
}
.services-overlay-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
    color: var(--tg-white);
}
.services-overlay-content .title a:hover {
    color: var(--tg-white);
}
.services-overlay-content p {
    margin-bottom: 20px;
    color: var(--tg-white);
}
.services-overlay-content .read-more {
    font-weight: 400;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 0 12px;
}
.services-overlay-content .read-more::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: var(--tg-heading-font-color);
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    z-index: -1;
    transition: 0.3s linear;
}
.services-overlay-content .read-more:hover::before {
    width: 100%;
}
.services-overlay-content .read-more i {
    margin-left: 5px;
}
.services-overlay-content {
    display: none;
}

/* services-two */
.services-item-two {
    background: var(--tg-white);
    padding: 24px;
    border-radius: 6px;
    margin-bottom: 30px;
}
.services-thumb-two {
    border-radius: 4px;
    overflow: hidden;
}
.services-thumb-two img {
    width: 100%;
    transition: 0.3s linear;
    transform: scale(1);
}
.services-item-two:hover .services-thumb-two img {
    transform: scale(1.1);
}
.services-content-two {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 0 24px;
}
.services-content-two .icon {
    width: 88px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-right: 25px;
    background: var(--tg-primary-color);
    border-radius: 4px;
    color: var(--tg-white);
    font-size: 40px;
    transform: translateY(-64px);
    z-index: 1;
}
.services-content-two .icon img {
    width: 40px;
}
.services-content-two .content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
}
.services-content-two .content p {
    margin-bottom: 0;
}
/* services-three */
.services-area-three {
    position: relative;
    padding: 115px 0 120px;
}
.services-area-three .services-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
}
.services-area-three .services-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(12, 18, 57, 0.92);
}
.services-nav-wrap .nav-tabs {
    border-bottom: none;
    justify-content: center;
    gap: 20px;
}
.services-nav-wrap .nav-tabs .nav-link {
    margin-bottom: 0;
    background: var(--tg-white);
    border: none;
    padding: 20px 42px;
    font-weight: 500;
    font-size: 20px;
    font-family: var(--tg-body-font-family);
    color: var(--tg-heading-font-color);
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    line-height: 1;
    z-index: 1;
    position: relative;
}
.services-nav-wrap .nav-tabs .nav-link.active {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}
.services-item-three {
    background: var(--tg-white);
    border-radius: 8px;
    padding: 66px 38px 50px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: -30px;
}
.services-content-three {
    width: 56%;
    flex: 0 0 auto;
}
.services-thumb-three {
    width: 44%;
    flex: 0 0 auto;
    padding-left: 46px;
}
.services-thumb-three img {
    width: 446px;
    height: 429px;
    object-fit: cover;
    border-radius: 10px;
}
.services-content-three p {
    margin-bottom: 30px;
}
.services-list .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
    gap: 15px 0;
    margin-bottom: 50px;
}
.services-list .list-wrap li {
    width: 50%;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    display: flex;
    align-items: center;
}
.services-list .list-wrap li i {
    font-size: 25px;
    color: var(--tg-primary-color);
    margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .services-thumb-three {
        width: 100%; /* Ajusta el ancho al 100% en pantallas más pequeñas */
        padding-left: 0;
    }

    .services-thumb-three img {
        width: 100%;
        height: auto; /* Permite que la altura se ajuste automáticamente */
        object-fit: cover;
    }

    .services-item-three {
        flex-direction: column; /* Cambia la dirección de flexión a columna en pantallas pequeñas */
        padding: 30px 20px; /* Ajusta el relleno para pantallas pequeñas */
    }

    .services-content-three {
        width: 100%;
    }

    .services-content-three p {
        margin-bottom: 20px; /* Reduce el margen inferior en pantallas pequeñas */
    }
}

/* inner-services */
.inner-services-area .services-item {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}

/* services-details */
.services-details-wrap {
    margin-right: 38px;
}
.services-details-thumb {
    margin-bottom: 55px;
}
.services-details-thumb img {
    border-radius: 10px;
}
.services-details-content > .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 22px;
}
.services-details-content p {
    margin-bottom: 0;
}
.services-process-wrap {
    margin-top: 40px;
    margin-bottom: 45px;
}
.services-process-img {
    position: relative;
    padding-bottom: 200px;
}
.services-process-img img {
    border-radius: 10px;
    width: 100%; /* Para que sea responsiva */
    height: auto; /* Para que mantenga la proporción */
    max-width: 332px; /* Ancho máximo */
    max-height: 376px; /* Alto máximo */
    object-fit: cover; /* Ajuste para mantener la proporción de la imagen */
}
.services-process-img img:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
}
.services-process-content {
    margin-left: 40px;
}
.services-process-content > .title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
}
.services-process-content .list-wrap li {
    margin-bottom: 20px;
}
.services-process-content .list-wrap li:last-child {
    margin-bottom: 0;
}
.services-process-item {
    display: flex;
    align-items: flex-start;
    background: var(--tg-gray);
    border-radius: 10px;
    padding: 20px;
}
.services-process-item .icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-white);
    font-size: 25px;
    flex: 0 0 auto;
    margin-right: 20px;
}
.services-process-item .content .title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 12px;
}
.services-process-item .content p {
    margin-bottom: 0;
}
.services-details-content .title-two {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.service-benefits-wrap {
    margin-top: 35px;
}
.benefits-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 15px;
}
.benefits-content p {
    margin-bottom: 20px;
}
.benefits-content .list-wrap li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 10px;
}
.benefits-content .list-wrap li:last-child {
    margin-bottom: 0;
}
.benefits-content .list-wrap li i {
    font-size: 24px;
    color: var(--tg-primary-color);
    margin-right: 15px;
}
.service-benefits-wrap .benefits-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.service-benefits-wrap .benefits-img img {
    border-radius: 10px;
    width: 230px; /* Ancho fijo para las imágenes */
    height: 294px; /* Alto fijo para las imágenes */
    object-fit: cover; /* Ajuste para mantener la proporción de la imagen */
}

.services-widget {
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 35px 40px 40px;
    background: var(--tg-white);
    margin-bottom: 50px;
}
.services-widget:last-child {
    margin-bottom: 0;
}
.our-services-list .list-wrap li {
    margin-bottom: 20px;
}
.our-services-list .list-wrap li:last-child {
    margin-bottom: 0;
}
.our-services-list .list-wrap li a {
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    background: var(--tg-gray);
    padding: 14px 30px;
    justify-content: space-between;
}
.our-services-list .list-wrap li a:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}
.services-widget.widget-bg {
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.services-widget.widget-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    z-index: -1;
}
.services-widget.widget-bg .widget-title {
    color: var(--tg-white);
}



.sidebar-form .form-grp {
    margin-bottom: 20px;
    
}
.sidebar-form .form-grp textarea,
.sidebar-form .form-grp input {
    width: 100%;
    border: none;
    display: block;
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    background: #f4f4f4;
    border-radius: 5px;
    padding: 15px 25px;
    height: 56px;
}
.sidebar-form .form-grp input::placeholder,
.sidebar-form .form-grp textarea::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #9a9a9a;
}
.sidebar-form .form-grp textarea {
    min-height: 175px;
    max-height: 175px;
}
.services-widget .download-wrap {
    display: flex;
    align-items: center;
    gap: 30px;
}
.services-widget .download-wrap a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 5px;
    padding: 25px 35px 20px;
}
.services-widget .download-wrap a i {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    color: var(--tg-primary-color);
    border-radius: 50%;
    font-size: 25px;
    margin-bottom: 15px;
}

/*=============================
	12. Support
===============================*/
.support-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.support-icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 35px;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    flex: 0 0 auto;
    margin-right: 30px;
}
.support-content .title {
    font-size: 24px;
    margin-bottom: 8px;
}
.support-content p {
    margin-bottom: 0;
    width: 85%;
}

/*=============================
	13. Project
===============================*/
.project-bg {
    background-size: cover;
    background-position: center;
    padding: 115px 0 95px;
    overflow: hidden;
}
.section-title.white-title .title {
    color: var(--tg-white);
}
.project-item {
    position: relative;
    background: var(--tg-blue);
    border-radius: 10px;
    padding: 10px 50px;
    min-height: 220px;
    transition: 0.3s linear;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}
.project-item:hover {
    background: var(--tg-gray);
}
.project-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.project-content .left-side-content {
    width: 35%;
    flex: 0 0 auto;
}
.project-content span {
    display: block;
    font-weight: 400;
    font-size: 18px;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 12px;
    transition: 0.3s linear;
}
.project-content .title {
    color: var(--tg-white);
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 0;
}
.project-item:hover .project-content .title {
    opacity: 0;
    visibility: hidden;
    height: 0;
}
.project-content p {
    display: none;
    margin-bottom: 0;
}
.project-item:hover .project-content p {
    height: auto;
    opacity: 1;
    visibility: visible;
}
.project-link {
    flex-grow: 1;
}
.project-link a {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
    color: var(--tg-primary-color);
    margin-left: auto;
}
.project-thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(19deg);
    right: 16%;
    z-index: 9;
    display: none;
}
.project-thumb img {
    border-radius: 20px;
}
.project-item {
    min-height: 180px;
}
.section-title .title {
    font-size: 38px;
}

/* project-two */
.project-area-two {
    overflow: hidden;
}
.project-item-two {
    position: relative;
    overflow: hidden;
}
.project-thumb-two img {
    width: 100%;
}
.project-content-two {
    position: absolute;
    left: initial;
    top: 0;
    background: rgb(254 93 20 / 80%);
    padding: 40px 40px 50px 40px;
    border-radius: 8px;
    width: 396px;
    height: 345px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200px);
    transition: 0.3s;
}
.project-item-two:hover .project-content-two {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.project-content-two span {
    display: block;
    color: var(--tg-white);
    margin-bottom: 8px;
}
.project-content-two .title {
    margin-bottom: 30px;
    color: var(--tg-white);
    font-size: 26px;
}
.project-content-two .title a:hover {
    color: var(--tg-white);
}
.project-content-two .link-btn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
    font-size: 25px;
}
.project-active .slick-list {
    overflow: visible;
}
.project-active .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;
}
.project-active .slick-dots li {
    list-style: none;
    line-height: 0;
}
.project-active .slick-dots li button {
    text-indent: -9999999999px;
    background: #87828f;
    padding: 0;
    border: none;
    width: 20px;
    height: 5px;
    line-height: 1;
    border-radius: 500px;
}
.project-active .slick-dots li.slick-active button {
    width: 40px;
    background: var(--tg-blue-two);
}

/* project-three */
.project-area-three {
    position: relative;
    padding: 115px 0 120px;
    z-index: 1;
}
.project-bg-two {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 60%);
    z-index: -1;
}
.project-area-three .project-thumb-two {
    border-radius: 8px;
    overflow: hidden;
}
.project-area-three .project-item-two {
    margin-bottom: 30px;
}

/* project-four */
.project-item-four {
    margin-bottom: 50px;
}
.project-thumb-four {
    border-radius: 10px;
    overflow: hidden;
}
.project-thumb-four img {
    width: 100%;
    transform: scale(1);
    transition: 0.3s linear;
}
.project-item-four:hover .project-thumb-four img {
    transform: scale(1.1);
}
.project-content-four {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 25px 25px;
    background: var(--tg-white);
    margin: 0 45px;
    justify-content: space-between;
    margin-top: -78px;
    position: relative;
    z-index: 1;
}
.project-content-four .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 14px;
}
.project-content-four .list-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
}
.project-content-four .list-wrap li {
    position: relative;
    line-height: 1;
}
.project-content-four .list-wrap li::before {
    content: "/";
    position: absolute;
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--tg-primary-color);
}
.project-content-four .list-wrap li:first-child::before {
    display: none;
}
.project-content-four .list-wrap li a {
    font-size: 16px;
}
.project-content-four .list-wrap li a:hover {
    color: var(--tg-heading-font-color);
}
.project-content-four .content-right .link-btn {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #115278;
    border-radius: 5px;
    font-size: 24px;
    color: var(--tg-white);
}
.project-content-four .content-right .link-btn i {
    transform: rotate(-40deg);
    transition: all 0.3s ease-out 0s;
}
.project-content-four .content-right .link-btn:hover {
    background: var(--tg-primary-color);
}
.project-content-four .content-right .link-btn:hover i {
    transform: rotate(0);
}
.project-active-two [class*="col-"] {
    padding: 0 15px;
}
.project-active-two .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.project-active-two .slick-dots li {
    list-style: none;
    line-height: 0;
}
.project-active-two .slick-dots li button {
    text-indent: -9999999999px;
    border: none;
    padding: 0;
    width: 20px;
    height: 5px;
    border-radius: 500px;
    background: var(--tg-primary-color);
    opacity: 0.2;
}
.project-active-two .slick-dots li.slick-active button {
    width: 40px;
    opacity: 1;
}

/* inner-project */
.inner-project-area .project-item-two {
    margin-bottom: 30px;
}
.inner-project-area .project-thumb-two {
    border-radius: 8px;
    overflow: hidden;
}

/* project-details */
.project-details-wrap {
    margin-right: 38px;
}
.project-details-thumb {
    margin-bottom: 55px;
}
.project-details-thumb img {
    border-radius: 10px;
}
.project-details-content > .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.project-details-content p {
    margin-bottom: 15px;
}
.project-details-content .info-two {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 25px;
}
.project-details-content .info-two span {
    color: var(--tg-primary-color);
}
.project-challenge-wrap {
    margin-bottom: 60px;
}
.project-challenge-wrap .challenge-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.project-challenge-wrap .challenge-content .info-three {
    margin-bottom: 20px;
}
.project-challenge-wrap .challenge-content .info-four {
    margin-bottom: 0;
}
.project-challenge-wrap .challenge-img {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}
.project-challenge-wrap .challenge-img img {
    border-radius: 5px;
}
.project-details-content .title-two {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.project-details-content .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 0;
    width: 90%;
}
.project-details-content .list-wrap li {
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    width: 50%;
}
.project-details-content .list-wrap li i {
    font-size: 24px;
    color: var(--tg-primary-color);
    margin-right: 15px;
}
.project-widget {
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 35px 40px 40px;
    margin-bottom: 50px;
}
.project-widget:last-child {
    margin-bottom: 0;
}
.project-info-list .list-wrap li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.project-info-list .list-wrap li:last-child {
    margin-bottom: 0;
}
.project-info-list .list-wrap li span {
    font-weight: 500;
    color: var(--tg-heading-font-color);
    width: 36%;
}
.project-contact .list-wrap li {
    display: flex;
    align-items: baseline;
    font-family: var(--tg-heading-font-family);
    margin-bottom: 15px;
}
.project-contact .list-wrap li:last-child {
    margin-bottom: 0;
}
.project-contact .list-wrap li i {
    font-size: 20px;
    color: var(--tg-primary-color);
    margin-right: 20px;
}

/*=============================
	14. Appointment
===============================*/
.appointment-inner {
    filter: drop-shadow(0px 16px 88px rgba(0, 0, 0, 0.08));
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    padding: 70px 70px;
    position: relative;
}
.appointment-form .form-grp {
    position: relative;
    margin-bottom: 24px;
}
.appointment-form .form-grp textarea,
.appointment-form .form-grp input {
    width: 100%;
    color: var(--tg-heading-font-color);
    font-weight: 400;
    font-size: 14px;
    font-family: var(--tg-body-font-family);
    border: 1px solid var(--tg-input-border-color);
    border-radius: 5px;
    background: var(--tg-white);
    padding: 17px 60px 17px 20px;
    height: 58px;
    transition: 0.3s linear;
    display: block;
}
.appointment-form .form-grp textarea:focus,
.appointment-form .form-grp input:focus {
    border-color: var(--tg-primary-color);
}
.appointment-form .form-grp textarea::placeholder,
.appointment-form .form-grp input::placeholder {
    color: #9aa2b1;
    font-weight: 400;
    font-size: 14px;
    font-family: var(--tg-body-font-family);
}
.appointment-form .form-grp label {
    position: absolute;
    right: 16px;
    top: 18px;
    font-size: 20px;
    color: var(--tg-blue-two);
    opacity: 0.4;
    line-height: 1;
}
.appointment-form .form-grp textarea {
    min-height: 145px;
    max-height: 145px;
    padding: 17px 20px 17px 20px;
}
.appointment-form .form-grp.select-grp select {
    background-color: var(--tg-white);
    border: 1px solid var(--tg-input-border-color);
    color: var(--tg-heading-font-color);
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 5px;
    outline: none;
    padding: 20px 50px 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    line-height: 1.2;
    height: 58px;
}
.appointment-form .form-grp.select-grp::after {
    content: "\f078";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    right: 20px;
    font-size: 15px;
    color: var(--tg-heading-font-color);
}
.appointment-form .btn {
    width: 100%;
}
.appointment-form .row {
    margin: 0 -12px;
}
.appointment-form .row [class*="col-"] {
    padding: 0 12px;
}
.appointment-img {
    position: absolute;
    right: 70px;
    bottom: 0;
    width: 482px;
}

/* appointment-two */
.appointment-area-two {
    margin-top: -130px;
    z-index: 1;
    position: relative;
}
.appointment-inner-two {
    background-size: cover;
    background-position: center;
    filter: drop-shadow(0px 16px 88px rgba(0, 0, 0, 0.08));
    border-radius: 8px;
    padding: 30px 40px 30px;
}
.appointment-inner-two .title {
    font-size: 40px;
    margin-bottom: 35px;
}
.appointment-inner-two .form-grp {
    margin-bottom: 25px;
}
.appointment-inner-two .form-grp textarea,
.appointment-inner-two .form-grp input {
    width: 100%;
    border: 1px solid var(--tg-input-border-color);
    border-radius: 5px;
    background: var(--tg-white);
    font-weight: 400;
    font-size: 14px;
    color: #9aa2b1;
    padding: 17px 20px;
    height: 58px;
    display: block;
}
.appointment-inner-two .form-grp textarea::placeholder,
.appointment-inner-two .form-grp input::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #9aa2b1;
}
.appointment-inner-two .form-grp textarea {
    min-height: 58px;
    max-height: 58px;
}
.appointment-inner-two form .btn {
    width: 100%;
    height: 58px;
}

/*=============================
	15. Team
===============================*/
.team-item {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}
.team-thumb {
    border-radius: 10px;
    position: relative;
    margin-left: auto;
    width: 278px;
}
.team-thumb img {
    border-radius: 10px;
    height: 435px;
    object-fit: cover;
    width: 100%;
}
.team-social {
    position: absolute;
    left: 0;
    top: 16px;
    transition: 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.team-item:hover .team-social {
    left: -50px;
    visibility: visible;
    opacity: 1;
}
.team-social .list-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--tg-gray-two);
    padding: 15px 9px;
    gap: 6px;
    border-radius: 5px 0 0 5px;
}
.team-social .list-wrap li a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    font-size: 14px;
    color: var(--tg-paragraph-color);
    border-radius: 50%;
}
.team-social .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.team-content {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    background: var(--tg-blue-two);
    border-radius: 10px;
    padding: 32px 20px;
    transition: 0.3s ease-in-out;
}
.team-item:hover .team-content {
    background: var(--tg-primary-color);
}
.team-content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
    color: var(--tg-white);
}
.team-content .title a:hover {
    color: var(--tg-white);
}
.team-content span {
    font-weight: 400;
    font-size: 16px;
    display: block;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
}

/* team-two */
.team-item-two {
    position: relative;
    margin-bottom: 30px;
}
.team-thumb-two {
    position: relative;
    overflow: hidden;
    border-radius: 5px 80px 5px 5px;
}
.team-thumb-two::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: 0.3s linear;
    pointer-events: none;
}
.team-item-two:hover .team-thumb-two::before {
    opacity: 1;
    width: 100%;
}
.team-social-two .list-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    top: 20px;
    gap: 7px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100px);
    transition: 0.3s;
}
.team-item-two:hover .team-social-two .list-wrap {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.team-social-two .list-wrap a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    background: var(--tg-white);
    color: var(--tg-paragraph-color);
}
.team-social-two .list-wrap a:hover {
    color: var(--tg-primary-color);
}
.team-content-two {
    width: 270px;
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    text-align: center;
    padding: 26px 20px;
    position: relative;
    z-index: 2;
    margin-top: -52px;
    transition: 0.3s linear;
    background: var(--tg-white);
}
.team-item-two:hover .team-content-two {
    background: var(--tg-primary-color);
}
.team-content-two .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
}
.team-content-two .title a:hover {
    color: var(--tg-white);
}
.team-content-two span {
    display: block;
    color: #636363;
    line-height: 1;
    transition: 0.3s linear;
}
.team-item-two:hover .team-content-two span,
.team-item-two:hover .team-content-two .title {
    color: var(--tg-white);
}

/* team-three */
.team-item-three {
    margin-bottom: 30px;
}
.team-thumb-three {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.team-thumb-three::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.3s linear;
    pointer-events: none;
    opacity: 0;
}
.team-item-three:hover .team-thumb-three::before {
    opacity: 1;
}
.team-social-three .list-wrap {
    display: flex;
    flex-direction: column;
    background: var(--tg-primary-color);
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px 8px;
    border-radius: 0 4px 0 4px;
    gap: 6px;
    border: 7px solid var(--tg-white);
    border-top: none;
    border-right: none;
    opacity: 0;
    transform: translateX(100px);
    visibility: hidden;
    transition: 0.3s linear;
}
.team-item-three:hover .team-social-three .list-wrap {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
}
.team-social-three .list-wrap li a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    font-size: 14px;
    color: var(--tg-paragraph-color);
    border-radius: 50%;
}
.team-social-three .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.team-content-three {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 25px 25px;
    text-align: center;
    background: var(--tg-white);
    border: 1px solid var(--tg-white);
    margin: -50px 35px 0;
    position: relative;
    z-index: 1;
    transition: 0.3s linear;
}
.team-item-three:hover .team-content-three {
    background: var(--tg-primary-color);
}
.team-item-three .team-content-three a:hover {
    color: var(--tg-white);
}
.team-content-three .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.team-content-three span {
    display: block;
    font-family: var(--tg-heading-font-family);
    line-height: 1;
    transition: 0.3s linear;
}
.team-item-three:hover .team-content-three span,
.team-item-three:hover .team-content-three .title {
    color: var(--tg-white);
}

/* team-details */
.team-details-thumb img {
    border-radius: 10px;
}
.info-content-top {
    margin-bottom: 55px;
}
.team-details-info .info-content-top .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 15px;
}
.team-details-info .info-content-top span {
    display: block;
    font-size: 20px;
    line-height: 1;
}
.team-details-info .list-wrap.team-info {
    border-top: 1px solid var(--tg-gray);
    border-bottom: 1px solid var(--tg-gray);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 35px;
}
.team-details-info .list-wrap.team-info li {
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
}
.team-details-info .list-wrap.team-info li:last-child {
    margin-bottom: 0;
}
.team-details-info .list-wrap.team-info li span {
    font-weight: 500;
    font-size: 20px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    width: 35%;
}
.team-details-info .list-wrap.team-info li i {
    color: var(--tg-primary-color);
    margin-right: 30px;
}
.team-details-social {
    display: flex;
    align-items: center;
}
.team-details-social .title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
    margin-right: 40px;
}
.team-details-social .list-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}
.team-details-social .list-wrap li a {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 20px;
    line-height: 1;
}
.team-details-content {
    margin: 70px 0 0;
}
.team-details-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.team-details-content p {
    margin-bottom: 0;
}
.team-details-content .info-one {
    margin-bottom: 25px;
}
.progress-wrap {
    background: var(--tg-gray);
    padding: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 25px 100px;
    margin-top: 70px;
    margin-bottom: 70px;
}
.progress-wrap .progress-item {
    width: 45%;
}
.progress-item .progress-bar {
    background-color: var(--tg-primary-color);
    overflow: inherit;
    height: 4px;
    border-radius: 2px;
    margin: 3px;
    position: relative;
}
.progress-item .progress-bar::before {
    content: "";
    position: absolute;
    right: -4px;
    bottom: 10px;
    width: 12px;
    height: 10px;
    background: var(--tg-primary-color);
    clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.progress-item .progress {
    height: 10px;
    background-color: rgba(254 93 20 / 15%);
    overflow: inherit;
    border-radius: 10px;
    position: relative;
}
.progress-item .progress span {
    display: block;
    position: absolute;
    right: 0;
    bottom: 22px;
    color: var(--tg-paragraph-color);
    line-height: 1;
    font-weight: 400;
    font-size: 16px;
}
.progress-item .title {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 13px;
    color: var(--tg-paragraph-color);
    font-family: var(--tg-body-font-family);
}
.guidelines-img img {
    border-radius: 5px;
}
.guidelines-content .title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}
.guidelines-content p {
    margin-bottom: 28px;
}
.guidelines-content .list-wrap li {
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    padding-left: 20px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 6px;
}
.guidelines-content .list-wrap li:last-child {
    margin-bottom: 0;
}
.guidelines-content .list-wrap li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--tg-heading-font-color);
}

/* area-bg */
.area-bg {
    position: relative;
    padding: 120px 0 0;
    z-index: 1;
}
.area-bg .area-background-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 280px);
    z-index: -1;
}
.area-bg .area-background-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 20, 55, 0.92);
}

.area-bg-two {
    background-size: cover;
    background-position: center;
    padding: 115px 0 120px;
}

.area-bg-three {
    background-size: cover;
    background-position: center;
    padding: 115px 0 120px;
}

.area-bg-four {
    background-size: cover;
    background-position: center;
    padding: 115px 0 90px;
}

.area-bg-five {
    background-size: cover;
    background-position: center;
    padding: 115px 0 0;
}

/*=============================
	16. Engineer
===============================*/
.engineer-item {
    box-shadow: 0px 16px 88px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background: var(--tg-white);
    padding: 20px 24px 40px;
    margin-bottom: 50px;
}
.engineer-thumb {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.engineer-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(297.19deg, #000000 0.95%, rgba(217, 217, 217, 0) 140.77%);
    pointer-events: none;
    opacity: 0.8;
}
.engineer-thumb .play-btn {
    width: 76px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    color: var(--tg-primary-color);
    z-index: 2;
    font-size: 24px;
    position: absolute;
    right: 30px;
    bottom: 30px;
    border-radius: 50%;
}
.engineer-info-wrap {
    display: flex;
    align-items: center;
    padding: 22px 0 0;
}
.engineer-info-wrap .thumb {
    width: 74px;
    flex: 0 0 74px;
    margin-right: 20px;
}
.engineer-info-wrap .thumb img {
    border-radius: 50%;
}
.engineer-info-wrap .content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
}
.engineer-info-wrap .content span {
    display: block;
    color: var(--tg-primary-color);
    line-height: 1;
}
.engineer-active [class*="col-"] {
    padding: 0 15px;
}
.engineer-active .slick-slide {
    transform: scale(0.85);
    transition: 0.4s linear;
    position: relative;
}
.engineer-active .slick-slide.slick-current.slick-active {
    transform: scale(1);
}
.engineer-active .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 10px;
}
.engineer-active .slick-dots li {
    list-style: none;
    line-height: 0;
}
.engineer-active .slick-dots li button {
    text-indent: -999999999px;
    border: none;
    padding: 0;
    opacity: 0.2;
    border-radius: 500px;
    width: 20px;
    height: 5px;
    background: var(--tg-primary-color);
    line-height: 1;
}
.engineer-active .slick-dots li.slick-active button {
    opacity: 1;
    width: 40px;
}

/*=============================
	17. Work
===============================*/
.work-area {
    background: var(--tg-gray);
    padding: 115px 0 90px;
}
.work-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.work-thumb {
    width: 94px;
    flex: 0 0 94px;
    position: relative;
    margin-right: 25px;
}
.work-thumb .number {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-gray);
    border-radius: 50%;
    font-weight: 600;
    font-size: 16px;
    color: var(--tg-white);
    right: 20px;
    top: 0;
    z-index: 1;
    margin-bottom: 0;
}
.work-thumb .number::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background: var(--tg-primary-color);
    border-radius: 50%;
    z-index: -1;
}
.work-content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 18px;
}
.work-content p {
    margin-bottom: 0;
}

/* work-two */
.work-bg {
    background-size: cover;
    background-position: center;
    padding: 115px 0 120px;
}
.work-inner {
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 700px;
    border-radius: 16px;
    border: 8px solid var(--tg-white);
    filter: drop-shadow(0px 16px 88px rgba(0, 0, 0, 0.08));
    position: relative;
}
.work-tooltip-wrap .tooltip-item {
    position: absolute;
}
.work-tooltip-wrap .tooltip-btn {
    width: 54px;
    height: 54px;
    color: var(--tg-primary-color);
    background: var(--tg-white);
    font-size: 22px;
    transition: 0.3s linear;
}
.work-tooltip-wrap .tooltip-btn.pulse::before {
    background: var(--tg-white);
    transition: 0.3s linear;
}
.work-tooltip-wrap .tooltip-content {
    bottom: 95px;
}
.work-tooltip-wrap .tooltip-item:nth-child(1) {
    left: 10%;
    top: 13%;
    right: auto;
}
.work-tooltip-wrap .tooltip-item:nth-child(2) {
    right: 31%;
    top: 9%;
    bottom: auto;
}
.work-tooltip-wrap .tooltip-item:nth-child(3) {
    left: 34%;
    top: 43%;
    bottom: auto;
}
.work-tooltip-wrap .tooltip-item:nth-child(4) {
    top: 29%;
    right: 42%;
}
.work-tooltip-wrap .tooltip-item:nth-child(5) {
    top: 35%;
    right: 21%;
}
.work-tooltip-wrap .tooltip-item:nth-child(6) {
    bottom: 15%;
    right: 16%;
}
.work-tooltip-wrap .tooltip-item:nth-child(7) {
    bottom: 12%;
    right: 45%;
}
.work-tooltip-wrap .tooltip-item.top .tooltip-content {
    bottom: auto;
    top: 95px;
}
.work-tooltip-wrap .tooltip-item.active .tooltip-btn {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}
.work-tooltip-wrap .tooltip-item.active .tooltip-btn.pulse::before {
    background: var(--tg-primary-color);
}

/*=============================
	18. History
===============================*/
.history-img-wrap {
    position: relative;
}
.history-img-wrap .list-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 67px 48px 0;
}
.history-img-wrap .list-wrap li {
    position: relative;
    z-index: 1;
}
.history-img-wrap .list-wrap li img {
    border-radius: 10px;
    object-fit: cover; /* Hace que las imágenes mantengan su proporción al adaptarse */
}
.history-img-wrap .list-wrap li:nth-child(1) img {
    width: 401px;
    height: 440px;
}
.history-img-wrap .list-wrap li:nth-child(2) img {
    width: 217px;
    height: 218px;
}
.history-img-wrap .list-wrap li:nth-child(3) img {
    width: 342px;
    height: 250px;
}
.history-img-wrap .list-wrap li:nth-child(2)::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}
.history-img-wrap .list-wrap li .play-btn {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}
.history-img-wrap .list-wrap li .play-btn.pulse::before {
    background: var(--tg-white);
}
.history-img-wrap .list-wrap li:nth-child(3) {
    position: absolute;
    right: 67px;
    bottom: 0;
}
.history-content p {
    margin-bottom: 20px;
    color: #535353;
}
.history-list .list-wrap li {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 10px;
}
.history-list .list-wrap li:last-child {
    margin-bottom: 0;
}
.history-list .list-wrap li i {
    font-size: 24px;
    color: var(--tg-primary-color);
    margin-right: 15px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .history-img-wrap .list-wrap li img {
        width: 100%;
        height: auto;
    }
    .history-img-wrap .list-wrap li:nth-child(1) img {
        width: 100%;
        height: auto;
    }
    .history-img-wrap .list-wrap li:nth-child(2) img {
        width: 100%;
        height: auto;
    }
    .history-img-wrap .list-wrap li:nth-child(3) img {
        width: 100%;
        height: auto;
    }
}

/*=============================
	19. Counter
===============================*/
.counter-inner {
    background: var(--tg-gray);
    border-radius: 10px;
    padding: 40px 0 10px;
}
.counter-item {
    margin-bottom: 30px;
    text-align: center;
}
.counter-icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    border-radius: 50%;
    margin: 0 auto 30px;
    font-size: 35px;
    line-height: 1;
    color: var(--tg-white);
    transition: 0.3s ease-in-out;
    transform: translateY(0px);
}
.counter-item:hover .counter-icon {
    transform: translateY(5px);
}
.counter-content .count {
    display: block;
    font-weight: 700;
    line-height: 0.8;
    font-size: 40px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 8px;
}
.counter-content p {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
}

/* counter-two */
.counter-item-two {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.counter-item-two .counter-icon {
    width: 100px;
    height: 100px;
    margin: 0 20px 0 0;
}
.counter-area-two .counter-inner {
    background: var(--tg-white);
    padding: 40px 40px 10px;
}

/* counter-three */
.counter-item-three {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}
.counter-item-three::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%) rotate(15deg);
    background: var(--tg-white);
    width: 1px;
    height: 142px;
    opacity: 0.28;
}
.counter-area-three .row [class*="col-"]:first-child .counter-item-three::before {
    display: none;
}
.counter-item-three .counter-content span {
    color: var(--tg-primary-color);
}
.counter-item-three .counter-content P {
    font-size: 20px;
    color: var(--tg-white);
}
.counter-content p {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
}

/*=============================
	20. Testimonial
===============================*/
.testimonial-area {
    background: var(--tg-gray);
}
.testimonial-img {
    margin-right: 71px;
}
.testimonial-img img {
    border-radius: 10px;
}
.testimonial-icon {
    font-size: 60px;
    line-height: 1;
    color: var(--tg-primary-color);
    margin-bottom: 25px;
    margin-left: 2px;
}
.testimonial-content > p {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 32px;
}
.testimonial-avatar {
    display: flex;
    align-items: center;
}
.testimonial-avatar .avatar-thumb {
    width: 80px;
    flex: 0 0 auto;
    margin-right: 30px;
}
.testimonial-avatar .avatar-thumb img {
    border-radius: 50%;
}
.testimonial-avatar .avatar-content .title {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 25px;
}
.testimonial-avatar .avatar-content p {
    margin-bottom: 0;
    color: var(--tg-primary-color);
}
.testimonial-active .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.testimonial-active .slick-dots li {
    list-style: none;
    line-height: 0;
}
.testimonial-active .slick-dots li button {
    text-indent: -99999999999px;
    padding: 0;
    border: none;
    background: #fdddce;
    width: 20px;
    height: 5px;
    line-height: 1;
    border-radius: 500px;
}
.testimonial-active .slick-dots li.slick-active button {
    width: 40px;
    background: var(--tg-primary-color);
}

/* testimonial-two */
.testimonial-inner {
    padding: 0 98px;
}
.testimonial-item-two {
    background: var(--tg-white);
    border-radius: 4px 50px 4px 4px;
    padding: 40px;
    display: flex !important;
    align-items: flex-start;
}
.testimonial-avatar-two {
    width: 150px;
    flex: 0 0 auto;
    margin-right: 50px;
}
.testimonial-avatar-two img {
    border-radius: 5px;
}
.testimonial-content-two .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.testimonial-content-two .content-top .icon {
    font-size: 40px;
    color: var(--tg-primary-color);
    line-height: 1;
}
.testimonial-content-two .content-top .rating {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--tg-primary-color);
    gap: 5px;
}
.testimonial-content-two p {
    margin-bottom: 23px;
    font-style: italic;
    font-size: 20px;
    position: relative;
    padding-left: 10px;
}
.testimonial-content-two p::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 90%;
    background: var(--tg-primary-color);
}
.testimonial-content-two .content-bottom .title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
}
.testimonial-content-two .content-bottom span {
    display: block;
    color: var(--tg-primary-color);
    line-height: 1;
}

/* testimonial-three */
.testimonial-area-three {
    background: var(--tg-gray);
    position: relative;
}
.testimonial-avatar-wrap .list-wrap li {
    position: absolute;
    z-index: -1;
}
.testimonial-avatar-wrap .list-wrap li img {
    border-radius: 50%;
    border: 8px solid var(--tg-white);
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
}
.testimonial-avatar-wrap .list-wrap li:nth-child(1) {
    width: 114px;
    height: 114px;
    left: 8%;
    top: 15%;
}
.testimonial-avatar-wrap .list-wrap li:nth-child(2) {
    width: 58px;
    height: 58px;
    left: 15%;
    top: 45%;
}
.testimonial-avatar-wrap .list-wrap li:nth-child(3) {
    width: 68px;
    height: 68px;
    left: 9%;
    bottom: 22%;
}
.testimonial-avatar-wrap .list-wrap li:nth-child(4) {
    width: 68px;
    height: 68px;
    right: 10%;
    top: 12%;
}
.testimonial-avatar-wrap .list-wrap li:nth-child(5) {
    width: 58px;
    height: 58px;
    right: 15%;
    top: 45%;
}
.testimonial-avatar-wrap .list-wrap li:nth-child(6) {
    width: 114px;
    height: 114px;
    right: 10%;
    bottom: 12%;
}
.testimonial-active-two .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;
}
.testimonial-active-two .slick-dots li {
    list-style: none;
    line-height: 0;
}
.testimonial-active-two .slick-dots li button {
    text-indent: -9999999999px;
    border: none;
    padding: 0;
    width: 20px;
    height: 5px;
    border-radius: 500px;
    background: var(--tg-primary-color);
    opacity: 0.2;
}
.testimonial-active-two .slick-dots li.slick-active button {
    width: 40px;
    opacity: 1;
}

/*=============================
	21. Faq
===============================*/
.faq-bg {
    background-size: cover;
    background-position: center;
    padding: 115px 0 120px;
}
.faq-wrap .accordion-item {
    box-shadow: 0px 10px 10px rgba(198, 198, 198, 0.19);
    border-radius: 4px;
    overflow: hidden;
    border: none;
    margin-bottom: 30px;
    background: var(--tg-white);
}
.faq-wrap .accordion-item:last-child {
    margin-bottom: 0;
}
.faq-wrap .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.faq-wrap .accordion-button {
    font-weight: 500;
    font-size: 18px;
    padding: 22px 25px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    background: var(--tg-white);
    border-bottom: 1px solid transparent;
    position: relative;
}
.faq-wrap .accordion-button::after {
    flex-shrink: 0;
    width: auto;
    height: auto;
    content: "\f067";
    background-image: none;
    font-size: 20px;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    position: absolute;
    right: 20px;
    top: 20px;
    color: var(--tg-heading-font-color);
}
.faq-wrap .accordion-button:not(.collapsed)::after {
    background-image: none;
    content: "\f068";
    transform: rotate(0);
}
.faq-wrap .accordion-button:not(.collapsed) {
    color: var(--tg-primary-color);
    background-color: var(--tg-white);
    box-shadow: none;
    border-bottom: 1px solid #e7e7e7;
}
.faq-wrap .accordion-button:focus {
    border-color: none;
    box-shadow: none;
}
.faq-wrap .accordion-body {
    padding: 22px 25px;
}
.faq-wrap .accordion-body p {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 1.83;
}
.faq-img-wrap {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: flex-end;
    position: relative;
}
.faq-img-wrap img:nth-child(1) {
    border-radius: 12px;
    width: 332px;
    height: 628px;
    object-fit: cover;
}
.faq-img-wrap img:nth-child(2) {
    border-radius: 12px 80px 12px 12px;
    width: 332px;
    height: 628px;
    object-fit: cover;
}
.faq-img-wrap .overlay-text {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%, rgba(255, 255, 255, 0) 100%);
    border-radius: 12px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    padding: 28px 25px;
    width: 460px;
    text-align: center;
    margin: 0 auto;
}
.faq-img-wrap .overlay-text .title {
    -webkit-text-fill-color: var(--tg-white);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--tg-blue-two);
    margin-bottom: 0;
    font-size: 90px;
    text-transform: uppercase;
    line-height: 0.8;
}

/*=============================
	22. Blog
===============================*/
.blog-post-item {
    box-shadow: 0px 16px 88px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
}
.blog-post-thumb {
    border-radius: 5px;
    overflow: hidden;
}
.blog-post-thumb img {
    transform: scale(1);
    transition: 0.3s ease-in-out;
}
.blog-post-item:hover .blog-post-thumb img {
    transform: scale(1.1);
}
.blog-post-content {
    padding: 50px 35px 30px;
    position: relative;
}
.blog-post-content .tag {
    font-weight: 400;
    font-size: 16px;
    border-radius: 5px;
    position: absolute;
    left: 30px;
    top: -25px;
    background: #ffefe7;
    display: block;
    padding: 12px 20px;
}
.blog-post-content .tag:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}
.blog-meta .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 30px;
    margin-bottom: 15px;
}
.blog-meta .list-wrap li {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.blog-meta .list-wrap li i {
    font-size: 15px;
    margin-right: 8px;
    color: var(--tg-primary-color);
}
.blog-meta .list-wrap li a {
    color: var(--tg-paragraph-color);
    margin-left: 5px;
}
.blog-meta .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.blog-post-content .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--tg-border-color);
    line-height: 1.5;
    padding-bottom: 15px;
    margin-bottom: 17px;
}
.blog-post-content .title a {
    color: inherit;
    display: inline;
    padding-bottom: 0;
    -webkit-transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(currentColor),
        color-stop(98%, currentColor)
    );
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 98%);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
}
.blog-post-content .title a:hover {
    background-size: 100% 1px;
}
.blog-post-content .link-btn {
    font-weight: 400;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    color: var(--tg-paragraph-color);
}
.blog-post-content .link-btn i {
    margin-left: 7px;
}
.blog-post-content .link-btn:hover {
    color: var(--tg-primary-color);
}

/* blog-two */
.blog-area-two .blog-post-item {
    border-radius: 5px 60px 5px 5px;
}
.blog-area-two .blog-post-thumb {
    border-radius: 5px 60px 5px 5px;
}

/* blog-sidebar */
.blog-widget {
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 35px 40px 40px;
    background: var(--tg-white);
    margin-bottom: 50px;
}
.blog-widget:last-child {
    margin-bottom: 0;
}
.widget-title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 10px;
}
.widget-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
        74.56deg,
        rgba(165, 26, 244, 0.1) 8.85%,
        rgba(240, 167, 14, 0.1) 57.29%,
        rgba(245, 110, 22, 0.1) 100%
    );
}
.widget-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 1px;
    background: var(--tg-primary-color);
}
.sidebar-search form {
    position: relative;
}
.sidebar-search form input {
    width: 100%;
    border: none;
    background: rgb(254 93 20 / 15%);
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-heading-font-color);
    border-radius: 5px;
    padding: 19px 70px 19px 30px;
    height: 60px;
}
.sidebar-search form input::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #636363;
}
.sidebar-search form button {
    border: none;
    background: transparent;
    font-size: 26px;
    padding: 0;
    color: var(--tg-primary-color);
    line-height: 1;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.categories-list .list-wrap li {
    border-bottom: 1px solid rgb(254 93 20 / 15%);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.categories-list .list-wrap li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding: 0;
}
.categories-list .list-wrap li a {
    font-size: 16px;
    color: var(--tg-paragraph-color);
    display: flex;
    align-items: center;
}
.categories-list .list-wrap li a span {
    margin-left: auto;
}
.categories-list .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.rc-post-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(254 93 20 / 15%);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.rc-post-item:last-child {
    margin-bottom: 0;
    padding: 0;
    border-bottom: none;
}
.rc-post-item .rc-post-thumb {
    width: 92px;
    flex: 0 0 auto;
    margin-right: 20px;
}
.rc-post-item .rc-post-thumb img {
    border-radius: 5px;
}
.rc-post-content .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.55;
}
.rc-post-content span {
    display: block;
    font-size: 14px;
    color: #636363;
}
.rc-post-content span i {
    color: var(--tg-primary-color);
    margin-right: 15px;
}
.tag-list-wrap .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}
.tag-list-wrap .list-wrap li a {
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    background: var(--tg-gray);
    display: block;
    border-radius: 5px;
    padding: 7px 20px;
}
.tag-list-wrap .list-wrap li a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}
.blog-widget.widget-bg {
    background-size: cover;
    background-position: center;
    min-height: 552px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.blog-widget.widget-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: -1;
}
.widget-bg .widget-title {
    color: var(--tg-white);
}
.widget-bg .widget-title::after {
    background: var(--tg-white);
}
.sidebar-content {
    margin-top: 30px;
}
.sidebar-content .title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 18px;
    color: var(--tg-white);
}
.sidebar-content p {
    color: var(--tg-white);
    margin-bottom: 25px;
    font-family: var(--tg-heading-font-family);
}

/* blog-details */
.blog-details-wrap {
    margin-right: 58px;
}
.blog-details-thumb img {
    border-radius: 5px;
}
.blog-details-thumb {
    margin-bottom: 40px;
}
.blog-details-content > .title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
}
.blog-details-content p {
    margin-bottom: 20px;
}
.blog-details-content blockquote {
    text-align: center;
    background: var(--tg-gray);
    border-radius: 5px;
    padding: 30px 60px;
    margin: 35px 0;
}
.blog-details-content blockquote p {
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 25px;
}
.blog-details-content blockquote cite {
    font-style: italic;
    font-weight: 600;
    font-size: 21px;
    line-height: 1;
    position: relative;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
}
.blog-details-content blockquote cite::before {
    content: "";
    position: absolute;
    left: -75px;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 2px;
    background: var(--tg-primary-color);
}
.blog-details-content blockquote cite::after {
    content: "";
    position: absolute;
    right: -75px;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 2px;
    background: var(--tg-primary-color);
}
.blog-details-content .bd-inner-img-wrap {
    margin: 35px 0 35px;
}
.blog-details-content .bd-inner-img-wrap img {
    border-radius: 5px;
}
.bd-inner-content .list-wrap li {
    display: flex;
    align-items: baseline;
    color: var(--tg-heading-font-color);
    margin-bottom: 3px;
}
.bd-inner-content .list-wrap li:last-child {
    margin-bottom: 0;
}
.bd-inner-content .list-wrap li i {
    color: var(--tg-primary-color);
    font-size: 14px;
    margin-right: 15px;
}
.blog-details-bottom .post-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 12px;
}
.blog-details-bottom .post-tags .title {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
}
.blog-details-bottom .post-tags .list-wrap {
    display: flex;
    align-items: center;
    gap: 10px 20px;
}
.blog-details-bottom .post-tags .list-wrap li a {
    line-height: 1;
    color: var(--tg-paragraph-color);
}
.blog-details-bottom .post-tags .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.blog-details-bottom .blog-post-share {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 12px;
    justify-content: flex-end;
}
.blog-details-bottom .blog-post-share .title {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
}
.blog-details-bottom .blog-post-share .list-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}
.blog-details-bottom .blog-post-share .list-wrap li a {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    border-radius: 50%;
}
.blog-details-bottom .blog-post-share .list-wrap li a:hover {
    background: var(--tg-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    color: var(--tg-primary-color);
}
.pev-next-post-wrap {
    border-top: 1px solid var(--tg-gray-three);
    padding-top: 70px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.pev-next-post-wrap .post-item {
    margin-bottom: 30px;
}
.pev-next-post-wrap .post-inner {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.pev-next-post-wrap .post-inner .thumb {
    width: 92px;
    flex: 0 0 auto;
    margin-right: 20px;
}
.pev-next-post-wrap .post-inner .thumb img {
    border-radius: 5px;
}
.pev-next-post-wrap .post-inner .content .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.pev-next-post-wrap .post-inner .content span {
    font-size: 14px;
    display: block;
}
.pev-next-post-wrap .post-inner .content span i {
    color: var(--tg-primary-color);
    margin-right: 15px;
}
.pev-next-post-wrap .post-item.next-post-item {
    text-align: right;
}
.pev-next-post-wrap .post-item.next-post-item .post-inner {
    flex-direction: row-reverse;
}
.pev-next-post-wrap .post-item.next-post-item .post-inner .thumb {
    margin-right: 0;
    margin-left: 20px;
}
.pev-next-post-wrap .post-btn a {
    background: #f4f4f4;
    border-radius: 3px;
    font-weight: 400;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    padding: 11px 20px;
    font-family: var(--tg-heading-font-family);
}
.pev-next-post-wrap .post-btn a:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}
.pev-next-post-wrap .post-btn a i {
    margin-right: 7px;
}
.pev-next-post-wrap .post-btn.next-btn a {
    flex-direction: row-reverse;
}
.pev-next-post-wrap .post-btn.next-btn a i {
    margin-right: 0;
    margin-left: 7px;
}
.comments-wrap {
    border-top: 1px solid var(--tg-gray-three);
    padding-top: 70px;
    padding-bottom: 40px;
}
.comments-wrap-title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 35px;
}
.comments-box {
    background: var(--tg-gray);
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    padding: 35px 35px;
    margin-bottom: 30px;
}
.comments-avatar {
    width: 68px;
    flex: 0 0 auto;
    margin-right: 25px;
}
.comments-avatar img {
    border-radius: 50%;
}
.comments-text .avatar-name {
    margin-bottom: 20px;
}
.comments-text .avatar-name .name {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
}
.comments-text .comment-reply-link {
    font-size: 15px;
    color: var(--tg-primary-color);
    font-family: var(--tg-heading-font-family);
}
.comments-text .comment-reply-link i {
    margin-right: 7px;
}
.comments-text .comment-reply-link:hover {
    color: var(--tg-blue-three);
}
.comments-text .avatar-name .date {
    font-size: 16px;
    display: block;
    line-height: 1;
    color: #4a4949;
}
.comments-text p {
    margin-bottom: 0;
    font-family: var(--tg-heading-font-family);
    color: #636363;
}
.latest-comments .children .comments-box {
    margin-left: 90px;
}
.comment-respond {
    border-top: 1px solid var(--tg-gray-three);
    padding-top: 70px;
}
.comment-reply-title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 40px;
}
.comment-form .form-grp {
    margin-bottom: 24px;
}
.comment-form .form-grp textarea,
.comment-form .form-grp input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    padding: 15px 25px;
    color: var(--tg-heading-font-color);
    height: 60px;
    display: block;
    background: var(--tg-gray);
}
.comment-form .form-grp textarea::placeholder,
.comment-form .form-grp input::placeholder {
    color: #9a9a9a;
    font-weight: 400;
    font-size: 14px;
}
.comment-form .row {
    margin: 0 -7.5px;
}
.comment-form .row [class*="col-"] {
    padding: 0 7.5px;
}
.comment-form .form-grp textarea {
    min-height: 170px;
    max-height: 170px;
}

/*=============================
	23. Pagination
===============================*/
.pagination-wrap .pagination.list-wrap {
    gap: 10px 14px;
}
.pagination-wrap .pagination .page-link {
    margin-left: 0;
    padding: 0 0;
    color: #636363;
    border: none;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1.5px solid var(--tg-gray-two);
    background: transparent;
    font-family: var(--tg-heading-font-family);
}
.pagination-wrap .pagination .page-item.active .page-link,
.pagination-wrap .pagination .page-link:hover {
    background: #f4f4f4;
    border-color: #f4f4f4;
    color: #252424;
}
.pagination-wrap .pagination .page-link:focus {
    color: #252424;
    background-color: #f4f4f4;
    outline: 0;
    box-shadow: none;
}
.pagination-wrap .pagination .page-item.next-page .page-link {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
}

/*=============================
	24. Newsletter
===============================*/
.section-title.white-title-two .sub-title {
    color: var(--tg-white);
}
.section-title.white-title-two .title {
    color: var(--tg-white);
}
.newsletter-bg {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    padding: 87px 0;
}
.newsletter-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0f1437;
    opacity: 0.92;
    z-index: -1;
}
.newsletter-form form {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.newsletter-form .form-grp {
    width: 260px;
}
.newsletter-form .form-grp input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-heading-font-color);
    border: none;
    background: var(--tg-white);
    border-radius: 5px;
    padding: 18px 20px;
    height: 58px;
}
.newsletter-form .form-grp input::placeholder {
    color: #9aa2b1;
    font-weight: 400;
    font-size: 14px;
}

/*=============================
	25. Contact
===============================*/
.contact-form-wrap {
    background-size: cover;
    background-position: center;
    padding: 65px 65px;
    border-radius: 5px;
}
.contact-form-wrap .title {
    font-size: 40px;
    margin-bottom: 10px;
}
.contact-form-wrap p {
    margin-bottom: 45px;
}
.contact-form-wrap .row {
    margin: 0 -10px;
}
.contact-form-wrap .row [class*="col-"] {
    padding: 0 10px;
}
.contact-form-wrap .form-grp {
    margin-bottom: 20px;
}
.contact-form-wrap .form-grp textarea,
.contact-form-wrap .form-grp input {
    width: 100%;
    border: 1px solid var(--tg-input-border-color);
    border-radius: 5px;
    background: var(--tg-white);
    font-size: 14px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    padding: 16px 15px;
    display: block;
    height: 56px;
}
.contact-form-wrap .form-grp textarea::placeholder,
.contact-form-wrap .form-grp input::placeholder {
    font-size: 14px;
    font-family: var(--tg-heading-font-family);
    color: #a4a4a4;
}
.contact-form-wrap .form-grp textarea {
    min-height: 180px;
    max-height: 180px;
}
.contact-form-wrap .contact-form .btn {
    width: 100%;
}
.contact-info-wrap {
    background: var(--tg-gray);
    padding: 60px 65px;
    border-radius: 5px;
    margin-bottom: 25px;
}
.contact-info-wrap .title {
    font-size: 40px;
    margin-bottom: 10px;
}
.contact-info-wrap p {
    margin-bottom: 45px;
}
.contact-info-wrap .list-wrap {
    display: flex;
    justify-content: space-around; /* Distribuye los elementos horizontalmente */
    gap: 25px;
}
.contact-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-info-item .icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 22px;
    margin-bottom: 10px; /* Espacio entre el icono y el texto */
}
.contact-info-item .content a {
    display: block;
    text-align: center; /* Centra el texto debajo del icono */
    color: var(--tg-heading-font-color);
}
.contact-info-item .content a:hover {
    color: var(--tg-primary-color);
}
.contact-info-item .content p {
    margin-bottom: 0;
    text-align: center; /* Centra el texto debajo del icono */
    color: var(--tg-heading-font-color);
}
#contact-map {
    height: 343px;
    width: 100%;
}
#contact-map iframe {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .contact-info-wrap {
        padding: 30px;
    }
    .contact-info-item .icon {
        width: 50px;
        height: 50px;
        font-size: 18px;
    }
    .contact-info-item .content a,
    .contact-info-item .content p {
        font-size: 14px;
    }
}

/*=============================
	26. Error
===============================*/
.error-img {
    text-align: center;
    margin-bottom: 40px;
}
.error-content {
    text-align: center;
}
.error-content .title {
    font-size: 40px;
    margin-bottom: 40px;
}

/*=============================
	27. Footer
===============================*/
.footer-bg {
    background-size: cover;
    background-position: center;
}
.footer-top {
    padding: 115px 0 120px;
}
.footer-widget {
    margin-bottom: 40px;
}
.fw-title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 25px;
    color: var(--tg-white);
}
.footer-content p {
    margin-bottom: 30px;
    color: var(--tg-gray-three);
}
.footer-newsletter .title {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 15px;
    color: var(--tg-white);
}
.footer-newsletter form {
    position: relative;
}
.footer-newsletter input {
    width: 100%;
    border: none;
    background: var(--tg-white);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-heading-font-color);
    padding: 14px 145px 14px 20px;
    height: 50px;
}
.footer-newsletter input::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #9a9a9a;
}
.footer-newsletter button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px 20px;
    border-radius: 0 4px 4px 0;
}
.footer-link .list-wrap li {
    margin-bottom: 7px;
}
.footer-link .list-wrap li:last-child {
    margin-bottom: 0;
}
.footer-link .list-wrap li a {
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-gray-three);
    display: inline-flex;
    align-items: center;
}
.footer-link .list-wrap li a i {
    margin-right: 10px;
}
.footer-link .list-wrap li a:hover {
    color: var(--tg-primary-color);
}
.footer-top .row [class*="col-"]:nth-child(2) .footer-widget {
    margin-left: 85px;
}
.footer-top .row [class*="col-"]:nth-child(3) .footer-widget {
    margin-left: 20px;
}
.footer-instagram .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}
.footer-instagram .list-wrap li a img {
    border-radius: 5px;
}
.footer-top .row [class*="col-"]:nth-child(4) .footer-widget {
    margin-left: 70px;
}
.footer-logo-area {
    background: var(--tg-secondary-color);
    border-radius: 5px;
    padding: 24px 50px;
    margin-top: 20px;
}
.footer-contact {
    display: flex;
    align-items: center;
    background: var(--tg-primary-color);
    border-radius: 5px;
    padding: 18px;
    margin: 0 15px;
}
.footer-contact .icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    color: #0f1437;
    background: var(--tg-white);
    border-radius: 5px;
    font-size: 40px;
    margin-right: 40px;
}
.footer-contact .content span {
    display: block;
    font-weight: 400;
    font-size: 18px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
    line-height: 1;
    margin-bottom: 3px;
}
.footer-contact .content a {
    font-weight: 600;
    font-size: 26px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
    display: block;
}
.footer-contact .content a:hover {
    color: var(--tg-secondary-color);
}
.footer-social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.footer-social .title {
    margin-bottom: 0;
    color: var(--tg-white);
    font-weight: 600;
    font-size: 25px;
    margin-right: 35px;
}
.footer-social .list-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}
.footer-social .list-wrap li a {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-white);
    color: var(--tg-primary-color);
    font-size: 20px;
}
.footer-social .list-wrap li a:hover {
    color: var(--tg-secondary-color);
}
.footer-bottom {
    background: var(--tg-secondary-color);
    padding: 22px 0;
}
.footer-bottom .copyright-text p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}
.footer-bootom-menu .list-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.footer-bootom-menu .list-wrap li {
    position: relative;
    margin-right: 30px;
    padding-right: 30px;
}
.footer-bootom-menu .list-wrap li:last-child {
    margin-right: 0;
    padding-right: 0;
}
.footer-bootom-menu .list-wrap li::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 34px;
    background: #2e2e2e;
}
.footer-bootom-menu .list-wrap li:last-child::before {
    display: none;
}
.footer-bootom-menu .list-wrap li a {
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}
.footer-bootom-menu .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

/*=============================
	28. Preloader
===============================*/
#preloader {
    background: var(--tg-white);
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0px;
    top: 0px;
    z-index: 999;
}
.ta-preloader-block {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    text-align: center;
}
.ta-spinner-eff {
    position: relative;
    width: 50px;
    height: 50px;
    -webkit-animation: taRotation 12.8s steps(1) 0s infinite;
    animation: taRotation 12.8s steps(1) 0s infinite;
}
.ta-spinner-eff .ta-bar {
    position: absolute;
    background-color: var(--tg-primary-color);
}
.ta-spinner-eff .ta-bar-top {
    width: 100%;
    height: 7px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    transform: scale(0, 1);
    -webkit-animation: bar-top 3.2s linear 0s infinite;
    animation: bar-top 3.2s linear 0s infinite;
}
.ta-spinner-eff .ta-bar-right {
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: left top;
    transform: scale(1, 0);
    -webkit-animation: bar-right 3.2s linear 0s infinite;
    animation: bar-right 3.2s linear 0s infinite;
}
.ta-spinner-eff .ta-bar-bottom {
    width: 100%;
    height: 7px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform-origin: right top;
    transform: scale(0, 1);
    -webkit-animation: bar-bottom 3.2s linear 0s infinite;
    animation: bar-bottom 3.2s linear 0s infinite;
}
.ta-spinner-eff .ta-bar-left {
    width: 7px;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left bottom;
    transform: scale(1, 0);
    -webkit-animation: bar-left 3.2s linear 0s infinite;
    animation: bar-left 3.2s linear 0s infinite;
}
@keyframes taRotation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes bar-top {
    0% {
        transform: scale(0, 1);
    }
    12.5% {
        transform: scale(1, 1);
    }
    87.5% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0, 1);
    }
}
@keyframes bar-right {
    0% {
        transform: scale(1, 0);
    }
    12.5% {
        transform: scale(1, 0);
    }
    25% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(1, 1);
    }
    87.5% {
        transform: scale(1, 0);
    }
    100% {
        transform: scale(1, 0);
    }
}
@keyframes bar-bottom {
    0% {
        transform: scale(0, 1);
    }
    25% {
        transform: scale(0, 1);
    }
    37.5% {
        transform: scale(1, 1);
    }
    62.5% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(0, 1);
    }
    100% {
        transform: scale(0, 1);
    }
}
@keyframes bar-left {
    0% {
        transform: scale(1, 0);
    }
    37.5% {
        transform: scale(1, 0);
    }
    50% {
        transform: scale(1, 1);
    }
    62.5% {
        transform: scale(1, 0);
    }
    100% {
        transform: scale(1, 0);
    }
}
